// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$font-styles: (
    "thin":        100,
    "extra-light": 200,
    "light":       300,
    "regular":     400,
    "medium":      500,
    "semi-bold":   600,
    "bold":        700,
    "extra-bold":  800,
    "black":       900
);
$letter-spacings: (
    "1": .15rem,
    "2": .2rem
);
$new-displays: (
    "5": 2.75rem
);

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Theme colors
$theme-colors: (
    'cinza-claro': #F8F7F7,
    'cinza-medio': #e8e8e8,
    'cinza-escuro': #5F5F5F,
    'vermelho': #a2121f,
    'portal-sei': #3081ac
);

// Size
$width-and-height: (
    "33": 33.3333333334%,
    "66": 66.6666666667%
);