#buscar-curso
{
    #buscar-dados-curso.hidden,
    #buscar-curso-carregando.hidden,
    #buscar-curso-nao-encontrado.hidden,
    #buscar-curso-falhou.hidden {
        max-height: 0;
        pointer-events: none;
        padding: 0;
    }

    #buscar-dados-curso,
    #buscar-curso-carregando,
    #buscar-curso-nao-encontrado,
    #buscar-curso-falhou {
        @include transition-max-height;
        overflow: hidden;
    }

    #buscar-curso-carregando,
    #buscar-curso-nao-encontrado,
    #buscar-curso-falhou {
        height: 50vh;
    }

    img {
        max-width: fit-content;
        max-height: 100%;
    }

    .border-light { 
        border-color: #bbb !important;
    }

    .user-profile .profile-picture {
        border-radius: initial;
    }

    #buscar-curso-descricao {
        width: inherit;
    }
    
}