// Font weights
@each $name, $size in $font-styles {
    .font-weight-#{$name} {
        font-weight: $size !important;
    }
}

// Letter spacings
@each $name, $size in $letter-spacings {
    .letter-spacing-#{$name} {
        letter-spacing: $size !important;
    }
}

// Displays
@each $name, $size in $new-displays {
    .display-#{$name} {
        font-size: $size;
        font-weight: 300;
        line-height: 1.2;
    }
}
