#timeGridCurto
{
    .fc-left h2 {
        font-size: 1.125rem; //h5 size
        margin-bottom: 0px;
    }
    .fc-right button { //btn-sm class
        padding: 0.25rem 0.5rem;
        font-size: 0.7875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }
    .fc-bg .fc-day {
        border-radius: 0;
    }
    .fc-slats tr td {
        height: 45px;
    }
    th.fac-day-header, th.fc-day-header {
        font-size: inherit;
    }
    .fc-event {
        padding: 0.5rem;
    }
}