@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,900&display=swap');
@import '../fonts/iconsmind/iconsmind.css';

@import 'variables';
@import 'mixins/transitions';

@import '~bootstrap/scss/bootstrap';
@import 'gull-admin/gull-admin';

@import 'components/cards/curso_em_andamento';
@import 'components/cards/matricula_solicitada';
@import 'components/buscar-curso';
@import 'components/cadastrar-curso';
@import 'components/full-calendar';
@import 'components/gerar-codigo-de-cadastro';
@import 'utilities/size';
@import 'utilities/typography';
@import 'extends/file__input';
@import 'extends/form__group';
@import 'extends/ladda_buttons';
@import 'extends/time_grid_curto';
@import 'extends/time_grid_logo';

@import '~@fullcalendar/core/main.min.css';
@import '~@fullcalendar/daygrid/main.min.css';
@import '~@fullcalendar/timegrid/main.min.css';
@import '~@fullcalendar/list/main.min.css';

@import '~cropperjs/dist/cropper.min.css';
@import '~jquery-autocomplete/jquery.autocomplete.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';
@import '~ladda/dist/ladda.min.css';

html
{
    scroll-behavior: smooth;
}
