.matricula_solicitada-autorizacao__autorizada
{
    min-width: .5rem;
    height: 100%;
    background-color: $green;
}

.matricula_solicitada-autorizacao__nao-autorizada
{
    min-width: .5rem;
    height: 100%;
    background-color: #6c757d;
}