#cadastrar-curso
{
    #cadastrar-dados-curso.hidden,
    #cadastrar-curso-carregando.hidden,
    #cadastrar-curso-nao-encontrado.hidden,
    #cadastrar-curso-falhou.hidden,
    #editar-ementa-certificado-esquerda.hidden,
    #cadastrar-curso-certificado.hidden,
    #buscar-curso-ementa.hidden {
        max-height: 0;
        pointer-events: none;
        padding: 0;
    }

    #cadastrar-dados-curso,
    #cadastrar-curso-carregando,
    #cadastrar-curso-nao-encontrado,
    #cadastrar-curso-falhou,
    #editar-ementa-certificado-esquerda,
    #cadastrar-curso-certificado,
    #buscar-curso-ementa {
        @include fadeInDown();
        overflow: hidden;
    }

    #cadastrar-curso-carregando,
    #cadastrar-curso-nao-encontrado,
    #cadastrar-curso-falhou {
        height: 50vh;
    }

    img {
        display: block;
        max-width: 100%;
        border: none;
    }

    .border-light { 
        border-color: #bbb !important;
    }

    .user-profile .profile-picture {
        border-radius: initial;
    }

    #cadastrar-curso-descricao #cadastrar-curso-requisito #cadastrar-curso-local{
        width: inherit;
    }

    #cadastrar-curso-turmas-table-tbody {
        tr { @include fadeInDown(); }
    }

    #cadastrar-curso-rodape-modal.hidden {
        max-height: 0;
        pointer-events: none;
        padding: 0;
    }

    #cadastrar-curso-rodape-modal {
        @include transition-max-height;
        overflow: hidden;
    }
}