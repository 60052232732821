#gerar-codigo-de-cadastro
{
    #usuario-nao-encontrado.hidden,
    #usuario-encontrado.hidden,
    #rodape-modal.hidden,
    #usuario-carregando.hidden,
    #usuario-falhou.hidden,
    #usuario-codigo-de-cadastro.hidden {
        max-height: 0;
        pointer-events: none;
        padding: 0;
    }

    #usuario-nao-encontrado,
    #usuario-encontrado,
    #rodape-modal,
    #usuario-carregando,
    #usuario-falhou,
    #usuario-codigo-de-cadastro {
        @include transition-max-height;
        overflow: hidden;
    }
    
    #usuario-nao-encontrado,
    #usuario-carregando,
    #usuario-falhou {
        height: 50vh;
    }

    #usuario-codigo-de-cadastro {
        height: 25vh;
    }

    #input-instituicao.hidden {
        visibility: hidden;
    }

    .instituicao-dropdown-autocomplete .xdsoft_autocomplete .xdsoft_autocomplete_dropdown {
        position: static;
        display: inline-block;
        margin-bottom: auto;
        overflow: auto;
    }
}