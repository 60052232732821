$solid-colors: (
    "blue": #003473,
    "midnight-blue": #0C0C3C,
    "indigo": #3F51B5,
    "dark-purple": #322740,
    "purple": #663399,
    "pink": #CB3066,
    "red": #f44336,
    "orange": #e97d23,
    "yellow": #ffc107,
    "green": #4caf50,
    "teal": #20c997,
    "cyan": #9c27b0,
    "gray": #2d2d33,
    "slate-gray": #405365
);

// Gradient colors
$gradient-colors: (
    gradient-purple-indigo: (
        "start-color": #663399,
        "end-color": #33214b
    ),
    gradient-black-blue: (
        'start-color' : #004e92,
        'end-color' : #000428
    ),
    gradient-black-gray: (
        'start-color' : #404040,
        'end-color' : #000000
    ),
    gradient-steel-gray: (
        'start-color' : #616d86,
        'end-color' : #1F1C2C
    )
);

// GLOBAL COLORS
$facebook: #3765c9;
$google: #ec412c;
$twitter: #039ff5;
$instagram:#c13584;
$linkedin:#0077b5;
$dribble:#ea4c89;
$youtube: #c4302b;
