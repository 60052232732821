.file__input
{
    position: relative;

    span.label {
        position: relative;
        z-index: 0;
        display: inline-block;
        background: map-get($theme-colors, 'primary');
        cursor: pointer;
        color: $background;
        padding: 3px 10px;
        font-size: 0.70rem;
        border-radius: 0.25rem
    }

    input {
        height: 25px;
        width: 59px;    
        display: inline-block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }
}
  
  
