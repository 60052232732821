.fc-left{
    font-family: $font-family-sans-serif;
}

.fc-toolbar h2 {
    color: #6c757d !important;
    // font-size: 1.1rem;
    margin: 0;
    margin-left: 2px;
}

.fc-button-primary{
    background-color: #3490dc;
    // overflow: visible;
}
.fc-button-primary:hover{
    visibility: none;
}
