.form__group {
    position: relative;
    width: 100%;
}
.form__group__text {
    position: relative;
    width: 500px;
}

.form__field {
    font-family: inherit;
    width: 90%;
    border: 0;
    border-bottom: 2px solid map-get($theme-colors, 'light');
    outline: 0;
    color: map-get($theme-colors, 'secondary');
    background: transparent;
    transition: border-color 0.2s;
  
    &::placeholder {
        color: transparent;
    }
  
    &:placeholder-shown ~ .form__label {
        cursor: text;
        top: 5px;
        color: map-get($theme-colors, 'secondary');
        font-weight: 300;
    }

    &:placeholder-shown {
        border-width: 1px;
        border-image: linear-gradient(to right, map-get($theme-colors, 'danger'), map-get($theme-colors, 'secondary'));
        border-image-slice: 1;
    }

    &:focus {
        padding-bottom: 6px;  
        font-weight: 700;
        border-width: 3px;
        border-image: linear-gradient(to right, map-get($theme-colors, 'primary'), map-get($theme-colors, 'secondary'));
        border-image-slice: 1;

        ~ .form__label {
            position: absolute;
            top: 0;
            display: block;
            transition: 0.2s;
            color: transparent;
            font-weight:700;
        }
    }

    &:required,
    &:invalid {
        box-shadow:none;
    }
}
  
.form__label {
    // position: absolute;
    // top: 0;
    display: block;
    // transition: 0.2s;
    color: rgb(0, 0, 0);
    font-weight:700;
}

.date__field {
    width: 10ch;
}

.date__field:invalid {
    width: 110px;
}
